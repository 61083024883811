import * as React from "react";
import "./style.css";
import "./_libs/p-swiper/p-swiper.css";
import pSwiper from "./_libs/p-swiper/p-swiper.js";
import AdminPage from "./admin-page/AdminPage";
import { collection, getDocs, query, orderBy, limit } from "firebase/firestore";
import NftPage from "./nft-page/NftPage";
import "animate.css";

import { db } from "./firebase";

function useEffectOnce(fn) {
  const ref = React.useRef(false);
  React.useEffect(() => {
    if (!ref.current) {
      fn();
    }
    return () => {
      ref.current = true;
    };
  }, []);
}

export default function App() {
  const [pages, setPages] = React.useState([]);
  const [displayNftPage, setDisplayNftPage] = React.useState(false);
  const [cvInfos, setCvInfos] = React.useState({
    cv_url: "",
    is_cv_enabled: false,
  });

  useEffectOnce(async () => {
    const results = await getDocs(
      query(collection(db, "pages"), orderBy("order"), limit(99))
    );
    setPages(results.docs.map((doc) => doc.data()));

    const cvResults = await getDocs(query(collection(db, "cv"), limit(1)));
    const cvData = cvResults.docs.at(0).data();
    setCvInfos(cvData);
  });

  React.useEffect(() => {
    pSwiper();

    // fixed layers
    const fixedLayerElements = document.querySelectorAll(".fixed-layer");
    let fixedLayersShown = false;
    function showFixedLayers() {
      console.log("showFixedLayers");
      for (const el of fixedLayerElements) {
        el.style.opacity = "1";
      }
    }
    function hideFixedLayers() {
      console.log("hideFixedLayers");
      for (const el of fixedLayerElements) {
        el.style.opacity = "0";
      }
    }
    document.body.querySelector(".p-swiper-page-vertical-layout") &&
      document.body
        .querySelector(".p-swiper-page-vertical-layout")
        .addEventListener("scroll", (e) => {
          if (window.innerHeight - e.target.scrollTop < 1) {
            if (fixedLayersShown === true) {
              return;
            }
            showFixedLayers();
            fixedLayersShown = true;
          } else {
            if (fixedLayersShown === false) {
              return;
            }
            hideFixedLayers();
            fixedLayersShown = false;
          }
        });
  });

  if (location.href.includes("admin")) {
    return <AdminPage />;
  }
  return (
    <React.Fragment>
      <div className="fixed-layer fixed-layer-title">William Victorino</div>
      <a
        href="mailto:williamvictorino@gmail.com"
        className="fixed-layer fixed-layer-contact"
      >
        Contact
      </a>
      <div className="p-swiper-page-vertical-layout">
        <div
          className="app-page p-swiper-page page-layout page-home"
          style={{ background: "#ffffff" }}
        >
          <div className="page-title">
            William <br />
            Victorino
          </div>

          {console.log({ cvInfos })}
          {cvInfos.is_cv_enabled && (
            <div
              className=""
              style={{
                position: "absolute",
                bottom: "0px",
                left: "0px",
                padding: "15px",
                fontSize: "20px",
                cursor: "pointer",
              }}
              onClick={() => {
                window.open(cvInfos.cv_url, "_blank");
              }}
            >
              CV
            </div>
          )}

          <div
            className="page-arrow page-arrow--down"
            p-swiper-navigate="true"
            p-swiper-navigate-direction="down"
          ></div>
        </div>

        {pages
          .filter((p) => p.title !== "In Situ")
          .map((page, pageIndex) => (
            <div
              key={pageIndex}
              className="p-swiper-page p-swiper-page-horizontal-layout"
              style={{ overflow: "hidden" }}
            >
              <div
                className="app-page p-swiper-page page-layout page-home"
                style={{ background: page.background }}
              >
                <div className="page-title">{page.title}</div>
                {page.title == "Dessins Numériques" && (
                  <div
                    className="animate__animated animate__pulse animate__infinite"
                    onClick={() => setDisplayNftPage(true)}
                    style={{
                      cursor: "pointer",
                      paddingTop: "10px",
                      color: "#98E371",
                      display: "inline-block",
                      position: "relative",
                      left: "14px",
                      top: "3px",
                      fontSize: "20px",
                    }}
                  >
                    Accès aux NFTs
                  </div>
                )}
                <div
                  className="page-arrow page-arrow--right"
                  p-swiper-navigate="true"
                  p-swiper-navigate-direction="right"
                ></div>
                {pageIndex < pages.length - 1 && (
                  <div
                    className="page-arrow page-arrow--down"
                    p-swiper-navigate="true"
                    p-swiper-navigate-direction="down"
                  ></div>
                )}
              </div>
              {page.medias.map((media, mediaIndex) => (
                <div
                  key={media.media_title}
                  className="app-page p-swiper-page"
                  style={{ background: page.background }}
                >
                  {media.text && (
                    <div className="app-page-content-text">
                      <div
                        dangerouslySetInnerHTML={{
                          __html: media.text.left_text,
                        }}
                      ></div>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: media.text.right_text,
                        }}
                      ></div>
                    </div>
                  )}

                  {!media.text && (
                    <div className="app-page-content">
                      <img
                        className="app-page-content-image-cover"
                        src={media.media_url}
                      />
                      <div className="app-page-content-image-description">
                        {media.media_title}
                      </div>
                    </div>
                  )}

                  <div
                    className="page-arrow page-arrow--left"
                    p-swiper-navigate="true"
                    p-swiper-navigate-direction="left"
                  ></div>
                  {mediaIndex < page.medias.length - 1 && (
                    <div
                      className="page-arrow page-arrow--right"
                      p-swiper-navigate="true"
                      p-swiper-navigate-direction="right"
                    ></div>
                  )}
                  {pageIndex < pages.length - 1 && (
                    <div
                      className="page-arrow page-arrow--down"
                      p-swiper-navigate="true"
                      p-swiper-navigate-direction="down"
                    ></div>
                  )}
                </div>
              ))}
            </div>
          ))}

        {/** IN SITU */}
        {pages
          .filter((p) => p.title === "In Situ")
          .map((page, pageIndex) => (
            <div
              key={pageIndex}
              className="p-swiper-page p-swiper-page-horizontal-layout"
              style={{ overflow: "hidden", height: "auto" }}
            >
              <div
                className="app-page p-swiper-page page-layout page-home"
                style={{ background: page.background }}
              >
                <div className="page-title">{page.title}</div>
                <div class="in-situ-gallery-grid">
                  {page.medias.map((media, mediaIndex) => (
                    <img src={media.media_url} />
                  ))}
                </div>
              </div>
            </div>
          ))}
      </div>

      {displayNftPage && (
        <NftPage onCloseNftPage={() => setDisplayNftPage(false)} />
      )}
    </React.Fragment>
  );
}
